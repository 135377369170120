import React from 'react';
import { useDataProvider, useTranslate, Title } from 'react-admin';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import createEditableRowOps from '../editableRowOps';
import authProvider from '../authProvider';
import { useDispatch } from 'react-redux';

const DepartmentList = props => {
    const endpoint = 'departments'
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);
    const permissions = authProvider.getPermissionsByResource(endpoint);

    function handleResetPasswordAction(rowData) {
        dataProvider.create('identity/resetuserpassword?userid=' + rowData.adminId,
        {
            data: null
        }).then(json => alert("New password: " + json["data"] + "\nWrite it down before closing this window"));
    }

    return (
        <div className="ListLayoutFullWidth">
            <Title title="resources.departments.name" />
            <EnhancedTable
                {...{
                    customActions: [
                        {
                            icon: 'cached',
                            tooltip: translate('resources.departments.customActions.resetPassword'),
                            onClick: (e, rowData) => handleResetPasswordAction(rowData)
                        }],
                    title: "resources.departments.name",
                    tableName: endpoint,
                    columns: [
                        { field: 'departmentId', hidden: true },
                        {
                            field: 'name',
                        },
                        {
                            field: 'isMain',
                            type: 'boolean',
                        },
                        {
                            field: 'adminUsername',
                            editable: 'never'
                        }
                    ],
                    editable: EditableRowOps([endpoint, 'departmentId', permissions], {onRowAdd: newData => handleResetPasswordAction(newData)}),
                }}
            />
        </div>
    );
};

export default DepartmentList;
