import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
// import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import SessionVREmbed from './SessionVREmbed';
import CogniteSessionResultsTable from './CogniteSessionResultsTable';
import IpgSessionModalContent from './IpgSessionModalContent';
import { useDataProvider } from 'react-admin';

const PaperComponent = props => (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
    </Draggable>
);

const dialogStyle = {
    root: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
        maxWidth: '100%'
    },
};

const NonModalDialog = withStyles(dialogStyle)(props => (
    <Dialog hideBackdrop {...props} />
));


const WindowContent = (sessionData) => {
    if (sessionData.sessionType == "IpgSession") {
        return <IpgSessionModalContent sessionData={sessionData}></IpgSessionModalContent>
    }
    else if (sessionData.sessionType == "RehabGameSession") {
        return sessionData.gameData.length > 0 ?
            <SessionVREmbed sessionId={sessionData.sessionId} commands={sessionData.gameData} module={sessionData.module}></SessionVREmbed>
            : <label style={{ color: "red" }}>No game data found in this session</label>
    }
    else if (sessionData.sessionType == "CogniteVRGameSession") {
        return sessionData.taskResults.length > 0 ?
            <CogniteSessionResultsTable taskResults={sessionData.taskResults} moduleId={sessionData.moduleId}></CogniteSessionResultsTable>
            : <label style={{ color: "red" }}>No game data found in this session</label>
    }
};

const SessionModal = ({ sessionId, togglePanel }) => {
    const [session, setSession] = useState();

    const dataProvider = useDataProvider();

    useEffect(
        () => {
            dataProvider
                .getOne(`sessions`, { id: sessionId })
                .then(response => {
                    setSession(response.data);
                });
        },
        [sessionId],
    );

    return (
        <NonModalDialog open={true} PaperComponent={PaperComponent} PaperProps={{ elevation: 3 }}>
            {session ?
                <DialogContent>
                    <p>{session.notes}</p>
                    {WindowContent(session)}
                </DialogContent>
                : <CircularProgress style={{ margin: "20px" }} />
            }
            <DialogActions>
                <Button onClick={togglePanel} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </NonModalDialog>
    );
};

export default SessionModal;
