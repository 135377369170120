import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDataProvider, Title, useTranslate } from 'react-admin';
import { MTableToolbar } from 'material-table';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import SpecialistTabs from './SpecialistTabs';
import createEditableRowOps from '../editableRowOps';
import reduxModules from '../redux-modules';
import authProvider from '../authProvider';

// const ModifiedToolbar = props => (
//     <div /*style={{ position: 'relative', bottom: '12px', height: '48px' }}*/>
//         <MTableToolbar {...props} />
//     </div>
// );

const SpecialistList = props => {
    const endpoint = 'specialists';
    const dispatch = useDispatch();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);
    const updateTabsAction = data => dispatch(reduxModules.updateTabs({ assignedPatients: data }));
    const permissions = authProvider.getPermissionsByResource(endpoint);

    const handleResetPasswordAction = (rowData) => {
        dataProvider.create('identity/resetuserpassword?userid=' + rowData.id, {
            data: null
        }).then(json => alert("New password: " + json["data"] + "\nWrite it down before closing this window"));
    };

    return (
        <div className="ListLayoutFullWidth">
            <Title title='resources.specialists.name' />
            <EnhancedTable
                {...{
                    customActions: [
                        {
                            icon: 'cached',
                            tooltip: translate('resources.specialists.customActions.resetPassword'),
                            onClick: (e, rowData) => handleResetPasswordAction(rowData)
                        }],

                    title: 'resources.specialists.name',
                    tableName: endpoint,
                    columns: [
                        { field: 'id', hidden: true },
                        { field: 'userName' },
                        { field: 'email' },
                        { field: 'name' },
                        { field: 'lastName' },
                        { field: 'position' },
                        { field: 'contacts' }
                    ],
                    customOptions: {
                        exportButton: true,
                        debounceInterval: 250,
                    },
                    editable: EditableRowOps([endpoint, 'id', permissions], { onRowAdd: newData => handleResetPasswordAction(newData) }),
                    onRowClick: (event, rowData) => {
                        dataProvider.getList(`specialistpatients?specialistid=${rowData.id}`)
                            .then(response => updateTabsAction(response.data));
                    },
                }}
            />
            <SpecialistTabs {...{ useSelector, translate }} />
        </div>
    );
};

export default SpecialistList;
