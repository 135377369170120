import englishMessages from 'ra-language-english';

export const messages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        switchLang: 'Switch Language',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        changePassword: {
            label: 'Change password',
            oldPw: 'Current password',
            newPw: 'New password', 
            newPwConfirm: 'Confirm new password',
            confirm: 'Submit',
            newPwShort: 'Password is too short',
            newPwNoUpper: 'At least one uppercase character is needed',
            newPwNoNumber: 'At least one number is needed',
            newPwMatchError: 'Passwords do not match',
            newPwEmpty: 'This field cannot be empty'
        },
    },

    resources: {
        specialists: {
            name: 'Specialists',
            fields: {
                userName: "User name",
                email: "Email",
                name: "Name",
                lastName: "Last name",
                position: "Position",
                contacts: "Contacts"
            },
            customActions: {
                resetPassword: 'Reset specialist password'
            },
            tabs: {
                assignedPatients: "Assigned patients",
                departments: "Departments",
            }
        },
        sessions: {
            name: "Sessions",
            fields:
            {
                startTime: 'Start time',
                endTime: 'End time',
                startDate: 'Date',
                notes: 'Notes',
                score: 'Score',
                patientUsername: 'Patient',
                sessionType: 'Type',
                moduleId: 'Module',
                sessionShortId: 'ID'
            }
        },
        devices: {
            name: "Devices",
            fields:
            {
                name: 'Name',
                moduleId: 'Module',
                macAdress: 'MAC adress',
                serialAdress: 'Serial adress'
            }
        },
        patients: {
            name: "Patients",
            fields:
            {
                id: 'Patient ID',
                userName: 'Code',
                currentDepartmentId: 'Department',
                contacts: 'Contacts',
                headWidth: 'Head width (mm)',
                headLength: 'Head length (mm)'
            },
            patientModules: "Related modules"
        },
        departments: {
            name: "Departments",
            fields:
            {
                name: 'Name',
                isMain: 'Main department',
                adminUsername: 'Administrator'
            },
            customActions: {
                resetPassword: 'Reset admin password'
            }
        },
        disabilities: {
            name: "Disabilities",
            fields:
            {
                name: 'Name',
                description: 'Description',
            },
            disabilityModules: 'Related modules'
        },
        modules: {
            name: "Modules",
            parameters: {
                saveBtn: 'Save'
            },
            fields:
            {
                name: 'Name',
                description: 'Description',
                assigned: "Assigned"
            },
        },
        relocationrequests: {
            name: "Relocations",
            namepending: "Pending relocation requests",
            namemy: "My relocation requests",
            fields:
            {
                relocationRequestId: "Relocation ID",
                patientUsername: 'Patient code',
                accepted: 'Accepted',
                requestTime: 'Request time',
                responseTime: 'Response time',
                oldDepartmentId: 'Old department',
                newDepartmentId: 'New department'
            },
            customActions: {
                relocate: 'Relocate',
                reject: 'Reject',
            }
        },
        cogniteresults: {
            name: "Task results",
            fields:
            {
                taskNo: '#',
                afterfake: 'Too fast stimulus?',
                correct: "Correct?",
                time: "Time"
            },
        },
    },

    notifications: {
        themeChange: 'Theme has been changed to %{theme}!',
        localeChange: 'Locale has been changed to %{locale}!',
    },

    enhancedTable: { 
        error: "Data could not be retrieved",
        grouping: {
            groupedBy: "Grouped By:",
            placeholder: "Drag headers here to group by",
        },
        pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
            labelRowsPerPage: "Rows per page:",
            labelRowsSelect: "rows",
            firstAriaLabel: 'First Page',
            firstTooltip: 'First page',
            previousAriaLabel: 'Previous Page',
            previousTooltip: 'Previous Page',
            nextAriaLabel: 'Next page',
            nextTooltip: 'Next page',
            lastAriaLabel: 'Last Page',
            lastTooltip: 'Last Page'
        },
        toolbar: {
            addRemoveColumns: 'Add or delete columns',
            nRowsSelected: '{0} row (s) selected',
            showColumnsTitle: 'Show columns',
            showColumnsAriaLabel: 'Show columns',
            exportTitle: 'Export',
            exportAriaLabel: 'Export',
            exportName: 'Export as CSV',
            exportCSVName: "Export as CSV",
            exportPDFName: "Export as PDF",
            searchTooltip: 'Search',
            searchPlaceholder: 'Patient code',
            refreshData: 'Refresh Data',
            toggleFiltering: 'Toggle Filtering',
        },
        header: {
            actions: 'Actions',
        },
        body: {
            filterRow: {
                filterTooltip: 'Filter'
            },
            editRow: {
                saveTooltip: "Save",
                cancelTooltip: "Cancel",
                deleteText: "Are you sure you want to delete this row?",
            },
            addTooltip: "Add",
            deleteTooltip: "Delete",
            editTooltip: "Edit",
            bulkEditTooltip: "Edit All",
            bulkEditApprove: "Save all changes",
            bulkEditCancel: "Discard all changes",
            emptyDataSourceMessage: 'No entries',
        },
    }

};

export default messages;
