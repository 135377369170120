import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = (process.env.REACT_APP_API_URL || window.location.origin) + '/api';
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const httpFile = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetch(url, options);
};

const generateParamQuery = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p) && obj[p] != undefined) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.length ? '?' + str.join("&") : '';
};

export default {
    // parseJwt: (token) => {
    //     let base64Url = token.split('.')[1];
    //     let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //     let jsonPayload = decodeURIComponent(
    //         atob(base64)
    //             .split('')
    //             .map(function(c) {
    //                 return (
    //                     '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    //                 );
    //             })
    //             .join('')
    //     );
    //     return JSON.parse(jsonPayload);
    // },
    getList: (resource, urlparams) =>
        httpClient(`${apiUrl}/${resource}` + generateParamQuery(urlparams)).then(({ json }) => ({
            data: json,
        })),

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getFile: (resource, params) =>
        httpFile(`${apiUrl}/${resource}/${params.id}`)
            .then(res => {
                if (res.status === 200 && res.redirected) {
                    window.location.href = res.url;
                    return Promise.resolve({ data: {} }); // returning object with empty data key, so react admin would not throw an error
                } else if (res.status === 404) {
                    return Promise.reject("File not found");
                } else {
                    return Promise.reject("Unexpected error");
                }
            }),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            return ({ data: params });
        }),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    /**
    Custom method for PATCH requests
    */
    updatePartial: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data)
        }).then(({ json }) => {
            return ({ data: json });
        }),

    create: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: json
        }))
            .catch(info => {
                throw {
                    ...{ info },
                    error: new Error()
                }
            })
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id ? params.id : ''}`, {
            method: 'DELETE',
            body: params.data && JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};
