import lithuanianMessages from 'ra-language-lithuanian';

export const messages = {
    ...lithuanianMessages,
    pos: {
        search: 'Ieškoti',
        configuration: 'Konfigūracija',
        switchLang: 'Keisti kalbą',
        language: 'Kalba',
        theme: {
            name: 'Tema',
            light: 'Light',
            dark: 'Dark',
        },
        changePassword: {
            label: 'Slaptažodžio keitimas',
            oldPw: 'Senas slaptažodis',
            newPw: 'Naujas slaptažodis', 
            newPwConfirm: 'Pakartokite naują slaptažodį',
            confirm: 'Pateikti',
            newPwShort: 'Slaptažodis per trumpas',
            newPwNoUpper: 'Reikalingas bent vienas simbolis iš didžiosios raidės',
            newPwNoNumber: 'Reikalingas bent vienas skaičius',
            newPwMatchError: 'Slaptažodžiai nesutampa',
            newPwEmpty: 'Šis laukelis negali būti tusčias'
        },
    },

    resources:
        {
        specialists: {
            name: "Specialistai",
            fields:
            {
                id: 'Nr.',
                userName: 'Vartotojo vardas',
                email: 'El. paštas',
                name: 'Vardas',
                lastName: 'Pavardė',
                position: 'Pareigos',
                contacts: 'Kontaktai'
            },
            customActions: {
                resetPassword: 'Atstatyti specialisto slaptažodį'
            },
            tabs: {
                assignedPatients: "Priskirti pacientai",
                departments: "Departmentai",
            }
        },
        sessions: {
            name: "Sesijos",
            fields:
            {
                startTime: 'Pradžios laikas',
                endTime: 'Pabaigos laikas',
                startDate: 'Data',
                notes: 'Pastabos',
                score: 'Taškai',
                patientUsername: 'Paciento Nr.',
                sessionType: 'Sesijos tipas',
                moduleId: 'Modulis',
                sessionShortId: 'ID'
            }
        },
        devices: {
            name: "Įrenginiai",
            fields:
            {
                deviceId: 'Nr',
                name: 'Pavadinimas',
                moduleId: 'Modulis',
                macAdress: 'MAC kodas',
                serialAdress: 'Serial kodas'
            }
        },
        patients: {
            name: "Pacientai",
            fields:
            {
                id: 'Paciento ID',
                userName: 'Kodas',
                currentDepartmentId: 'Padalinys',
                contacts: 'Kontaktai',
                headWidth: 'Galvos plotis (mm)',
                headLength: 'Galvos ilgis (mm)'
            },
            patientModules: "Susiję moduliai"
        },
        departments: {
            name: "Padaliniai",
            fields:
            {
                departmentId: 'Nr.',
                name: 'Pavadinimas',
                isMain: 'Centrinis padalinys',
                adminUsername: 'Administratorius'
            },
            customActions: {
                resetPassword: 'Atstatyti administratoriaus slaptažodį'
            }
        },
        disabilities: {
            name: "Sveikatos sutrikimai",
            fields:
            {
                name: 'Pavadinimas',
                description: 'Aprašymas',
            },
            disabilityModules: 'Susiję moduliai'
        },
        modules: {
            name: "Moduliai",
            parameters: {
                saveBtn: 'Išsaugoti'
            },
            fields:
            {
                name: 'Programos / Modulio pavadinimas',
                description: 'Aprašymas',
                assigned: "Priskirta"
            },
        },
        relocationrequests: {
            name: "Perkėlimai",
            namepending: "Nepatvirtintos perkėlimo užklausos",
            namemy: "Mano perkėlimo užklausos",
            fields:
            {
                relocationRequestId: "Perkėlimo Nr.",
                patientUsername: 'Paciento kodas',
                accepted: 'Priimta',
                requestTime: 'Prašymo sukūrimo laikas',
                responseTime: 'Atsakymo laikas',
                oldDepartmentId: 'Senas departamentas',
                newDepartmentId: 'Naujas departamentas'
            },
            customActions: {
                relocate: 'Perkelti',
                reject: 'Atmesti',
            }
        },
    },

    notifications: {
        themeChange: 'Tema pakeista į %{theme}!',
        localeChange: 'Kalba pakeista į %{locale}',
    },

    enhancedTable: { 
        error: "Nepavyko gauti duomenų",
        grouping: {
            groupedBy: "Grupuoti pagal:",
            placeholder: "Vilkite antraštes čia, kad sugrupuotumėte",
        },
        pagination: {
            labelDisplayedRows: "{from}-{to} iš {count}",
            labelRowsPerPage: "Įrašai per puslapį:",
            labelRowsSelect: "įrašai per puslapį",
            firstAriaLabel: 'Pirmas puslapis',
            firstTooltip: 'Pirmas puslapis',
            previousAriaLabel: 'Praeitas puslapis',
            previousTooltip: 'Praeitas puslapis',
            nextAriaLabel: 'Sekantis puslapis',
            nextTooltip: 'Sekantis puslapis',
            lastAriaLabel: 'Paskutinis puslapis',
            lastTooltip: 'Paskutinis puslapis'
        },
        toolbar: {
            addRemoveColumns: 'Pridėti arba šalinti stulpelius',
            nRowsSelected: '{0} eilutė (s) pasirinktos',
            showColumnsTitle: 'Rodyti stulpelius',
            showColumnsAriaLabel: 'Rodyti stulpelius',
            exportTitle: 'Eksportas',
            exportAriaLabel: 'Eksportas',
            exportName: 'Eksportuoti kaip CSV',
            exportCSVName: 'Eksportuoti kaip CSV',
            exportPDFName: 'Eksportuoti kaip PDF',
            searchTooltip: 'Paieška',
            searchPlaceholder: 'Paciento kodas',
            refreshData: 'Atnaujinti duomenis',
            toggleFiltering: 'Įjungti filtravima'
        },
        header: {
            actions: 'Veiksmai',
        },
        body: {
            filterRow: {
                filterTooltip: 'Filtras'
            },
            editRow: {
                saveTooltip: "Išsaugoti",
                cancelTooltip: "Atšaukti",
                deleteText: "Ar jūs esate tikras, kad norite pašalinti šią eilutę?",
            },
            addTooltip: 'Pridėti',
            deleteTooltip: 'Šalinti',
            editTooltip: 'Redaguoti',
            bulkEditTooltip: "Redaguoti viską",
            bulkEditApprove: "Išsaugoti visus pakeitimus",
            bulkEditCancel: "Atšaukti visus pakeitimus",
            emptyDataSourceMessage: 'Nėra įrašų',
        },
    }
};

// Corrections
messages.ra.auth.logout = "Atsijungti";

export default messages;
